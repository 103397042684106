import React, { Fragment, useCallback, useState, useContext, useMemo, useEffect } from 'react';
import { Card, CardBody, Button, Input, InputGroup, InputGroupAddon, InputGroupText, Col } from 'reactstrap';
import Loader from '../../components/common/Loader';
import { isIterableArray, IconSearch, search } from '../../utils';
import { useHistory } from 'react-router-dom';
import StoresTable from '../../components/stores/StoresTable';
import { lang } from '../../lang';
import AppContext from '../../context/Context';
import Alert from '../../components/alert/error';
import { request, APIs } from '../../request';
import Context from '../../context/Context';
const PAGE_LIMIT = 10;

const Branches = () => {
  const history = useHistory();
  const [showPackageError, setShowPackageError] = useState(false);
  const [paginatedData, setPaginatedData] = useState([]);
  const [searchResultCount, setSearchResultCount] = useState();
  const { stores, setStores } = useContext(Context);

  const onAddButtonClicked = useCallback(() => {
    history.push('/stores/add');
  }, [history]);

  const onSearchChange = e => {
    const res = search(stores, ['name_ar', 'name_en'], e.target.value);
    setSearchResultCount(res.length);
    paginate(1, res);
  };

  const paginate = useCallback(
    (page, source) => {
      source = source || stores;
      const res = source.slice(PAGE_LIMIT * (page - 1), PAGE_LIMIT * page);
      setPaginatedData(res);
    },
    [stores]
  );

  const fetchData = useCallback(async () => {
    const { data } = await request.GET(APIs.STORES);
    setStores(data.data);
  }, []);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  useEffect(() => {
    paginate(1, stores);
  }, [stores, paginate]);
  useEffect(() => {
    stores.map(store => {
      store.name_en = store.name.en;
      store.name_ar = store.name.ar;
    });
  }, [stores]);

  const length = searchResultCount || stores.length;
  const totalPages = useMemo(() => Math.ceil(length / PAGE_LIMIT), [length]);

  return (
    <Fragment>
      <div className="mb-4 d-flex">
        <Col md={6} lg={4} xl={3} className="mb-3">
          <InputGroup>
            <Input
              placeholder={lang.search}
              type={'text'}
              className="search-notification-box px-4"
              onChange={onSearchChange}
            />
            <InputGroupAddon addonType="append">
              <InputGroupText className="search-notification-box-icon bg-white search-notification-box">
                <IconSearch />
              </InputGroupText>
            </InputGroupAddon>
          </InputGroup>
        </Col>
        <Button color="primary" className="appts-btn" onClick={onAddButtonClicked}>
          {lang.stores.addStore}
        </Button>
      </div>
      <Card>
        <CardBody className="fs--1">
          {isIterableArray(stores) ? (
            <StoresTable stores={paginatedData} totalPages={totalPages} paginate={paginate} />
          ) : (
            <Loader />
          )}
        </CardBody>
      </Card>
      <Alert
        open={showPackageError}
        onClick={() => setShowPackageError(false)}
        title={lang.sorry}
        body={`${lang.stores.packageLimitExceeded}`}
      />
    </Fragment>
  );
};

export default Branches;
