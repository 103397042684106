import React, { useCallback, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { VacanciesCircle } from './VacanciesCircle';
import { CardBody, CustomInput } from 'reactstrap';
import { isAR, lang } from '../../lang';
import { date, groupBy, capitalize } from '../../utils';
import Flex from '../common/Flex';
import FalconCardHeader from '../common/FalconCardHeader';
import { Card } from '../common/Card';

export const VacanciesGraph = ({ services: raw_services, appts: raw_appts, className, loading }) => {
  const [services, setServices] = useState([]);
  const [selectedFilter, setSelectedFilter] = useState('1');

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const filters = useMemo(() => [lang.dashboardItems.hour, lang.dashboardItems.day], [isAR]);

  const prepareData = useCallback(() => {
    // get today appts only
    let todayAppts = raw_appts.filter(appt => {
      return !date.differenceInDays(new Date(), new Date(appt.start_datetime));
    });

    // if hour view is selected, then take only current hour's appts
    const currentHour = new Date().getHours();
    if (selectedFilter === '0') {
      // hour selected
      todayAppts = todayAppts.filter(appt => {
        return new Date(appt.start_datetime).getHours() === currentHour;
      });
    }

    // group appts by their service
    const groupedAppts = groupBy(todayAppts, 'service_id');

    // calculate booking for each service and update its total capacity for one hour or full working day. Note: result stored as service attribute/field.
    const updated_services = raw_services.map(s => ({
      ...s,
      capacity: (60 / s.duration) * (selectedFilter === '1' ? 8 : 1) * s.capacity, // TODO: replace 8 with actual working hours
      bookings: groupedAppts[s.id]?.length || 0,
    }));

    // update services with more detailed services
    setServices(updated_services);
  }, [selectedFilter, raw_appts, raw_services]);

  useEffect(() => {
    prepareData();
  }, [prepareData]);

  const onChange = e => setSelectedFilter(e.target.value);

  const targetedServices = services
    ?.sort((a, b) => {
      return b.bookings - a.bookings;
    })
    .slice(0, 4);
  console.log(services, 'targetedServices');

  return (
    <Card className={className} loading={loading}>
      <FalconCardHeader
        titleClass="dashboard-card-title"
        title={lang.dashboardItems.servicesVacancies}
        light={false}
        titleTag="h6"
        className="pb-0"
      >
        <Flex>
          <CustomInput
            type="select"
            bsSize="sm"
            className="select-month mr-2 lightgrey-yesser"
            value={selectedFilter}
            onChange={onChange}
          >
            {filters.map((filter, index) => (
              <option key={index} value={index}>
                {capitalize(filter)}
              </option>
            ))}
          </CustomInput>
        </Flex>
      </FalconCardHeader>
      <CardBody>
        <div className="container">
          {targetedServices?.map((s, index) => {
            return (
              <div key={s.id + s.bookings}>
                <div className="row justify-content-between mx-2 align-items-center" style={{ fontSize: '0.9rem' }}>
                  <div>{isAR ? s.name.ar : s.name.en}</div>
                  <div className="row align-items-center">
                    <div className="mx-1 row">
                      <div
                        className={`text-${
                          s.bookings / s.capacity < 0.5
                            ? 'success'
                            : s.bookings / s.capacity < 0.8
                            ? 'warning'
                            : 'danger'
                        } mx-1`}
                      >
                        {s.bookings}
                      </div>{' '}
                      / {s.capacity}
                    </div>
                    <div className="mx-4">
                      <VacanciesCircle used={s.bookings} total={s.capacity} />
                    </div>
                  </div>
                </div>
                {index !== targetedServices.length - 1 ? <hr className="dashboard-data-devider" /> : null}
              </div>
            );
          })}
        </div>
      </CardBody>
    </Card>
  );
};

VacanciesGraph.propTypes = {
  services: PropTypes.array.isRequired,
  appts: PropTypes.array.isRequired,
};
