import React, { useContext, useCallback, useState, useEffect } from 'react';
import { lang } from '../../lang';
import { Toggler } from '../common/toggler';
import ConfigCard from './_ConfigCard';
import AppContext from '../../context/Context';
import { safeReq } from '../../utils/request';
import { request, APIs } from '../../request';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { toast } from 'react-toastify';
import { APPOINTMENT_STATUS } from '../../constants';
import { date } from '../../utils';

export default () => {
  const [hasPendingAppointments, setHasPendingAppointments] = useState([]);
  const { selectedStore, setSelectedStore, stores, setStores } = useContext(AppContext);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [toggleValue, setToggleValue] = useState(!!selectedStore.configuration?.auto_accept_status);
  let storeId = selectedStore.id;

  const fetchPendingAppointments = useCallback(async () => {
    const {
      data: { data },
    } = await request.GET(APIs.APPOINTMENTS, {
      store_id: storeId,
      status: APPOINTMENT_STATUS.PENDING,
      from: date.format(new Date(), 'yyyy-MM-dd'),
    });
    setHasPendingAppointments(!!data.length);
  }, [storeId]);

  const onAutoAcceptChange = useCallback(
    async toggleValue => {
      await request.PATCH(APIs.CONFIG, {
        branch_id: storeId,
        auto_accept_status: toggleValue,
      });
      const {
        data: { data },
      } = await request.GET(APIs.STORES, {
        id: storeId,
      });
      setSelectedStore(data);
      setStores(stores.map(st => (st.id === data.id ? data : st)));

      toggleValue && toast.success(lang.configuration.autoAcceptActivated);
      !toggleValue && toast.success(lang.configuration.autoAcceptDeactivated);
    },
    [storeId, setSelectedStore, setStores, stores]
  );

  const onAcceptAllAppointmentClicked = useCallback(async () => {
    safeReq(async () => {
      await request.GET(APIs.ACCEPT_ALL_APPOINTMENTS, {
        branch_id: storeId,
      });
    });
    onAutoAcceptChange(true);
    setIsModalOpen(false);
  }, [onAutoAcceptChange]);

  const onDontAcceptAllAppointmentsClick = useCallback(async () => {
    onAutoAcceptChange(true);
    setIsModalOpen(false);
  }, [onAutoAcceptChange]);

  const onToggleChange = useCallback(
    toggleValue => {
      setToggleValue(toggleValue);
      if (toggleValue) {
        hasPendingAppointments ? setIsModalOpen(true) : onAutoAcceptChange(true);
      } else {
        onAutoAcceptChange(false);
      }
    },
    [onAutoAcceptChange, hasPendingAppointments]
  );

  useEffect(() => {
    fetchPendingAppointments();
  }, [fetchPendingAppointments]);

  return (
    <ConfigCard>
      <h5 className="font-weight-bold">{lang.configuration.appointmentsAutoAccept}</h5>
      <small className="m-0 px-3" style={{ flex: 1 }}>
        {lang.configuration.appointmentsAutoAcceptExplaination}
      </small>
      <Toggler checked={toggleValue} onChange={onToggleChange} />
      <Modal className="text-center" isOpen={isModalOpen} centered={true}>
        <ModalHeader className="d-flex justify-content-center">
          {lang.configuration.autoAcceptAllAppointmentsConfirmationTitle}
        </ModalHeader>
        <ModalBody>{lang.configuration.autoAcceptAllAppointmentsConfirmationBody}</ModalBody>
        <ModalFooter className="justify-content-center">
          <Button color="primary" className="px-4 py-2 mx-2" onClick={onAcceptAllAppointmentClicked}>
            {lang.yes}
          </Button>
          <Button color="primary" className="px-4 py-2 mx-2" onClick={onDontAcceptAllAppointmentsClick}>
            {lang.no}
          </Button>
          <Button
            className="px-4 py-2 mx-2"
            onClick={() => {
              setIsModalOpen(false);
              setToggleValue(false);
            }}
          >
            {lang.cancel}
          </Button>
        </ModalFooter>
      </Modal>
    </ConfigCard>
  );
};
