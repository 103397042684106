import React, { useCallback, useContext, useState } from 'react';
import AlertError from '../../components/alert/error';
import AppContext from '../../context/Context';
import { lang } from '../../lang';
import { APIs, request } from '../../request';
import { Toggler } from '../common/toggler';
import ConfigCard from './_ConfigCard';
import { toast } from 'react-toastify';

export default () => {
  const { selectedStore, stores, setStores, setSelectedStore } = useContext(AppContext);
  const [is_active, setActive] = useState(!!selectedStore?.configuration?.branch_status);
  const [showNoServiceAlert, setNoServiceAlert] = useState(false);

  const _updateLocalStoreState = useCallback(
    is_active => {
      let storeId = selectedStore.id;
      setSelectedStore(prevState => ({
        ...prevState,
        configuration: {
          ...prevState.configuration,
          branch_status: is_active,
        },
      }));
      const updatedStoresList = stores.map(st => {
        st.id === storeId && (st.active = is_active);
        return st;
      });
      setStores(updatedStoresList);
    },
    [selectedStore, setSelectedStore, stores, setStores]
  );

  const toggleStore = useCallback(
    async toggleState => {
      let storeId = selectedStore.id;
      setActive(toggleState);
      _updateLocalStoreState(toggleState);
      const {
        data: { data },
      } = await request.PATCH(APIs.CONFIG, {
        branch_id: storeId,
        branch_status: toggleState,
      });
      toggleState ? toast.success(lang.stores.activateSuccess) : toast.success(lang.stores.deactivateSuccess);
      // if (toggleState == data.branch_status) {
      // } else {
      //   setActive(data.active);
      //   _updateLocalStoreState(data.active);
      //   setNoServiceAlert(true);
      // }
    },
    [_updateLocalStoreState, selectedStore.id]
  );

  return (
    <ConfigCard>
      <h5 className="font-weight-bold">{lang.configuration.activationStatus}</h5>
      <small className="m-0 px-3" style={{ flex: 1 }}>
        {lang.configuration.activationStatusExplaination}
      </small>
      <Toggler onChange={toggleStore} checked={is_active} />
      <AlertError
        open={showNoServiceAlert}
        onClick={() => setNoServiceAlert(false)}
        title={lang.sorry}
        body={lang.stores.storeWithoutServiceMsg}
      />
    </ConfigCard>
  );
};
